<template>
  <div id="app">
    <div class="page-header panel">
      <h3 class="title">
        <router-link
          :to="{
            path: 'suggest-log',
            query: {
              date: this.$route.query.date
            }
          }"
          class="back-button"
          ><i class="el-icon-back"></i
        ></router-link>
        <span style="font-weight:bold">Жолоочийн suggest</span>
      </h3>
    </div>
    <el-row :gutter="20">
      <el-col :span="9">
        <div class="widget shady-panel">
          <div class="body">
            <el-table
              :data="data"
              v-if="data"
              @row-click="latLng"
              highlight-current-row
              row-class-name="cursor"
            >
              <el-table-column prop="driver_name" label="Жолоочийн нэр">
              </el-table-column>
              <el-table-column
                prop="case"
                label="Case"
                width="70"
              ></el-table-column>
              <el-table-column prop="case" label="Авсан эсэх" width="100">
                <template slot-scope="scope">
                  {{ scope.row.assigned_time ? "Авсан" : "Цуцалсан" }}
                </template>
              </el-table-column>
              <el-table-column prop="case" label="Огноо">
                <template slot-scope="scope">
                  {{
                    scope.row.assigned_time
                      ? scope.row.assigned_time
                      : scope.row.canceled_time
                  }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-col>
      <el-col :span="3">
        <gmap-map
          :center="center"
          :zoom="12"
          :options="options"
          :draggable="false"
          style="width:60vw;  height: 80vh; margin-top: 5px;margin-left:25%"
        >
          <gmap-polyline
            :path.sync="path"
            :options="{ strokeColor: '#008000' }"
          >
          </gmap-polyline>
          <div>
            <!-- Жолоочийн маркер -->
            <gmap-marker
              v-for="(driver, index) in driverLoc"
              :key="index"
              @click="polyLine(driver)"
              :position="{
                lat: parseFloat(driver.lat),
                lng: parseFloat(driver.lng)
              }"
            >
              <gmap-info-window
                :opened="driver.infoWindowsOpen"
                @closeclick="driver.infoWindowsOpen = false"
              >
                <div>
                  <span style="font-weight:bold"> Захиалгын дугаар:</span>
                  {{
                    driver.order.order_number
                      ? driver.order.order_number
                      : "Хоосон"
                  }}
                </div>
                <div>
                  <span style="font-weight:bold">Жолооч:</span>
                  {{ driver.driver_name }}
                </div>
              </gmap-info-window>
            </gmap-marker>
            <!-- Партнер маркер -->
            <gmap-marker
              v-for="partner in partnerLoc"
              :key="partner.order_number"
              :position="{
                lat: parseFloat(partner.lat),
                lng: parseFloat(partner.lng)
              }"
              :icon="{
                url: require('../assets/pin.png'),
                scaledSize: { width: 55, height: 55 }
              }"
            >
              <gmap-info-window
                :opened="infoWinOpen"
                @closeclick="infoWinOpen = false"
                v-if="partner.partner_name"
              >
                <div>
                  <span style="font-weight:bold"> Хаягын нэр:</span>
                  {{ partner.partner_name }}
                </div>
              </gmap-info-window>
            </gmap-marker>
            <!-- OrderPartner -->
            <gmap-marker
              v-for="partner in orderPartner"
              :key="partner.order_number"
              :position="{
                lat: parseFloat(partner.lat),
                lng: parseFloat(partner.lng)
              }"
              :icon="{
                url: require('../assets/pin.png'),
                scaledSize: { width: 55, height: 55 }
              }"
            ></gmap-marker>
            <!-- Хэрэглэгч маркер -->
            <gmap-marker
              v-for="user in userLoc"
              :key="user.order_number"
              :position="{
                lat: parseFloat(user.lat),
                lng: parseFloat(user.lng)
              }"
              :icon="{
                url: require('../assets/yellow-pin.png'),
                scaledSize: { width: 65, height: 65 }
              }"
            >
            </gmap-marker>
            <!-- orderUser -->
            <gmap-marker
              v-for="user in orderUser"
              :key="user.order_number"
              :position="{
                lat: parseFloat(user.lat),
                lng: parseFloat(user.lng)
              }"
              :icon="{
                url: require('../assets/yellow-pin.png'),
                scaledSize: { width: 65, height: 65 }
              }"
            >
            </gmap-marker>
          </div>
        </gmap-map>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// import services from "../helpers/services.js";
export default {
  name: "Address",
  components: {},
  data() {
    return {
      driverUid: "",
      path: [],
      orderPartner: null,
      orderUser: null,
      infoWinOpen: true,
      data: JSON.parse(this.$route.query.data),
      userLoc: [],
      partnerLoc: [],
      driverLoc: null,
      options: {
        scrollwheel: true,
        mapTypeControl: true,
        streetViewControl: false,
        minZoom: 4,
        maxZoom: 20,
        gestureHandling: "greedy"
      },
      center: {
        lat: 47.9174,
        lng: 106.9137
      }
    };
  },
  methods: {
    polyLine(data) {
      data.infoWindowsOpen = !data.infoWindowsOpen;
      this.infoWinOpen = true;
      this.orderUser = null;
      this.orderPartner = null;
      this.path = [];
      if (data.order.partner_loc) {
        // orderPartner
        var partnerData = {};
        const partner = [];
        partnerData = data.order.partner_loc.split(" ");
        partner.push({
          lat: partnerData[0],
          lng: partnerData[1],
          case: data.case
        });
        this.orderPartner = partner;
        // orderUser
        var userData = {};
        const user = [];
        userData = data.order.user_loc.split(" ");
        user.push({ lat: userData[0], lng: userData[1] });
        this.orderUser = user;
        if (data.case === 3 && this.driverUid === data.driver_uid) {
          this.path.push(
            {
              lat: parseFloat(partner[0].lat),
              lng: parseFloat(partner[0].lng)
            },
            { lat: parseFloat(data.lat), lng: parseFloat(data.lng) },
            { lat: parseFloat(user[0].lat), lng: parseFloat(user[0].lng) }
          );
        } else {
          this.path.push(
            { lat: parseFloat(data.lat), lng: parseFloat(data.lng) },
            {
              lat: parseFloat(partner[0].lat),
              lng: parseFloat(partner[0].lng)
            },
            { lat: parseFloat(user[0].lat), lng: parseFloat(user[0].lng) }
          );
        }
      }
    },
    latLng(row) {
      // userMarker
      var userMarker = null;
      const user = row.user_loc.split(" ");
      userMarker = {
        lat: user[0],
        lng: user[1]
      };
      this.userLoc.push(userMarker);
      // partnerMarker
      var partnerMarker = null;
      const partner = row.partner_loc.split(" ");
      partnerMarker = {
        lat: partner[0],
        lng: partner[1],
        partner_name: row.partner_name
      };
      this.partnerLoc.push(partnerMarker);
      // driverMarker
      const tempMarker = [];
      this.driverUid = row.driver_uid;
      row.drivers.forEach(el => {
        if (!el.canceled_time) {
          const tempData = el.driver_loc.split(" ");
          tempMarker.push({
            lat: tempData[0],
            lng: tempData[1],
            driver_name: el.driver_name,
            infoWindowsOpen: el.driver_name === row.driver_name,
            order: el.order,
            case: row.case,
            driver_uid: el.driver_uid
          });
        } else {
          this.path = [];
        }
      });
      this.driverLoc = tempMarker;
    }
  }
};
</script>

<style>
#app {
  overflow: hidden;
}
.el-table .cursor {
  cursor: pointer;
}
</style>
